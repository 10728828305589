.options_bar {
    display: grid;
    grid-template-columns: 20% auto 20%;
    grid-template-rows: 40px;
    grid-template-areas: ". search filter";
}

.search_area {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-area: search;
    color: white;
}

.search_area input {
    grid-column-start: 1;
    grid-column-end: 3;
    border-bottom-left-radius: 20px ;
    border-top-left-radius: 20px ;
    border: solid 1px white;
    background: transparent;
    color: white;
    font-family: "Times New Roman", serif;
    font-size: 16px;
    padding-left: 20px;
}

.search_btn {
    border: none;
}

.search_btn:hover:disabled {
    cursor: not-allowed;
}

.search_btn:hover {
    background: black;
    border: solid 1px white;
    color: white;
    cursor: pointer;
}

.filter_btn {
    background: transparent;
    color: white;
}

.filter_btn:hover {
    cursor: pointer;
}

.search_end {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}