.footer {
    display: grid;
    background: #151515;
    grid-template-columns: 20% auto 20%;
    grid-template-areas: ". disclaimer .";
    grid-template-rows: 80px;
}

.disclaimer {
    grid-area: disclaimer;
    font-size: 14px;
    color: white;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-family: 'serif';
}

/*Tablets*/
@media (min-width: 768px) and (max-width: 1024px) {
    .disclaimer {
        font-size: 10px;
    }
}

/*Phones */
@media (min-width: 320px) and (max-width: 480px) {
    .disclaimer {
        font-size: 9px;
    }
}
