.card-container {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 1rem;
}

.card {
    display: grid;
}

.card .button {
    align-self: end;
}
.card {
    box-shadow: 0px 1px 5px black;
    background-color: #282c34;
    color: white;
    height: 100%;
}

.card__title {
    font-family: 'Lucida Grande';
    font-size: 16px;
    padding-left: 10px;
    padding-right: 5px;
}

.card__description {
    font-family: 'Avenir';
    padding: .5rem;
    line-height: 1.6em;
}

.button {
    display: block;
    background-color: #282c34;
    padding: 10px 20px;
    color: white;
    border-top: solid black 1px;
    text-decoration: none;
    text-align: center;
    transition: .3s ease-out;
}
.button:hover {
    background-color: black;
    color: white;
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

img {
    max-width: 100%;
}