body {
    background: #282c34;
}

@font-face {
    font-family: 'Birabella';
    font-style: normal;
    font-weight: normal;
    src: local('Birabella'), url("./otfs/Birabella.otf") format('truetype');
}

@font-face {
    font-family: 'ColdWarm';
    font-style: normal;
    font-weight: normal;
    src: local('ColdWarm'), url("./otfs/ColdWarm.otf") format('truetype');
}

.top-container {
    display: grid;
    grid-template-columns: 20% auto 20%;
    grid-template-rows: auto;
    grid-template-areas:
            ". banner .";
}

.main-banner {
    grid-area: banner;
    justify-self: center;
    color: white;
    font-family: 'ColdWarm';
    font-size: 130px;
}

.bottom-banner {
    grid-area: banner;
    color: white;
    font-family: 'Birabella';
    font-size: 60px;
}

/*Tablets*/
@media (min-width: 768px) and (max-width: 1024px) {
    .main-banner {
        font-size: 80px;
    }

    .bottom-banner {
        font-size: 40px;
    }
}

@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {
    .main-banner {
        font-size: 80px;
    }

    .bottom-banner {
        font-size: 40px;
    }
}
/*Phones*/
@media (min-width: 320px) and (max-width: 480px) {
    .main-banner {
        font-size: 40px;
    }

    .bottom-banner {
        font-size: 20px;
    }
}